import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import CartItemAmount from '@theme/components/cart/CartItemAmount'
import CheckoutFreeDelivery from '@theme/components/cart/CheckoutFreeDelivery'
import CheckoutFreeGift from '@theme/components/cart/CheckoutFreeGift'
import Modal from '@theme/components/utils/Modal'
import PricesMixin from '~/mixins/PricesMixin'
import ProductBox from '@theme/components/product/ProductBox'
import ProductLinkMixin from '~/mixins/ProductLinkMixin'

export default {
  components: {
    AppImage,
    AppTitle,
    CartItemAmount,
    CheckoutFreeGift,
    CheckoutFreeDelivery,
    Modal,
    ProductBox,
  },
  mixins: [PricesMixin, ProductLinkMixin],
  props: {
    accessoryProducts: {
      type: Array,
      default: () => [],
    },
    addedItem: {
      type: Object,
      required: true,
    },
    changedAmount: {
      type: Number,
    },
    quantityMultiplier: {
      type: Number,
      default: null,
    },
    relatedProducts: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      required: true,
    },
  },
  async created() {
    if (this.$themeSettings.components.AddToCartModalItemAdded.displayedProducts === 'recommended') {
      this.recommendedProducts = await this.$store.dispatch('recommendation/LOAD_PRODUCTS', {
        context: {
          product: this.addedItem.product,
        },
        placement: 'addToCart__crossSell',
        size: 3,
      })
    }
  },
  data() {
    return {
      recommendedProducts: [],
    }
  },
  computed: {
    productPath() {
      const query = { variant_id: this.addedItem.shoppable.id }
      return this.getProductPath(this.addedItem.product, query)
    },
    addedAmount() {
      const quantityMultiplier = Number(this.quantityMultiplier)
      const changedAmount = Number(this.changedAmount).toFixed(this.decimalPosition)
      if (changedAmount && quantityMultiplier) {
        return Number((changedAmount / quantityMultiplier).toFixed(this.decimalPosition))
      } else {
        return changedAmount
      }
    },
    decimalPosition() {
      return this.quantityMultiplier ? this.$themeSettings.components.ProductMetersCalculator.decimalPosition || 0 : 0
    },
    itemFormattedPrices() {
      return this.getFormattedPrices({ prices: this.addedItem.prices, amount: this.changedAmount })
    },
    showAllSpecialMarketingOffers() {
      return (
        this.$themeSettings.components.AddToCartModalItemAdded.showFreeDelivery &&
        this.$store.state.cart.cartData?.prices?.freeShippingAt &&
        this.$themeSettings.components.AddToCartModalItemAdded.showAvailableGifts &&
        this.$store.state.globals.orderGiftAt
      )
    },
    showAvailableGifts() {
      return (
        this.$themeSettings.components.AddToCartModalItemAdded.showAvailableGifts &&
        this.$store.state.globals.orderGiftAt
      )
    },
    showFreeDelivery() {
      return (
        this.$themeSettings.components.AddToCartModalItemAdded.showFreeDelivery &&
        this.$store.state.cart.cartData?.prices?.freeShippingAt
      )
    },
    showAmount() {
      return (
        this.$themeSettings.components.AddToCartModalItemAdded.showChangedAmount ||
        this.$themeSettings.components.AddToCartModalItemAdded.showChangeAmountInput
      )
    },
    displayedProducts() {
      if (this.$themeSettings.components.AddToCartModalItemAdded.displayedProducts === 'accessory') {
        return this.accessoryProducts
      } else if (this.$themeSettings.components.AddToCartModalItemAdded.displayedProducts === 'recommended') {
        return this.recommendedProducts
      } else if (this.$themeSettings.components.AddToCartModalItemAdded.displayedProducts === 'related') {
        return this.relatedProducts
      }
      return []
    },
    displayedProductsTitle() {
      if (this.$themeSettings.components.AddToCartModalItemAdded.displayedProducts === 'accessory') {
        return this.$t('AddToCartModalItemAdded.accessoryProducts.heading')
      } else if (this.$themeSettings.components.AddToCartModalItemAdded.displayedProducts === 'recommended') {
        return this.$t('AddToCartModalItemAdded.recommendedProducts.heading')
      } else if (this.$themeSettings.components.AddToCartModalItemAdded.displayedProducts === 'related') {
        return this.$t('AddToCartModalItemAdded.relatedProducts.heading')
      }
      return []
    },
  },
  methods: {
    hide() {
      this.$refs.modal.hide()
    },
    show() {
      this.$refs.modal.show()
    },
  },
}
